@import "../../../data/styles.css";

.article {
	display: flex;
	flex-wrap: wrap; /* Allow elements to wrap to the next row on smaller screens */
}

.article a {
	text-decoration: none;
}

/* Limit image width so it doesn't overlap */
.article-container img {
	max-width: 100%; /* Limit image width to its container */
}


.article-right-side:hover {
	background: var(--secondary-background);
	opacity: 1;
	transition: background-color 0.6s ease-in-out;
}

.article-date {
	color: white;
	font-size: 14px;
	margin-top: -20px; /* Position over the bottom of the article */
	text-align: right; /* Align text to the right */
	width: 100%; /* Ensure it takes the full width of its container */
}

.article-date {
	padding-top: 30px;
	font-size: 15px;
	color: var(--tertiary-color);
}

.article-title {
	color: #000000;
	font-size: 16px;
	text-align: left;
	font-weight: 600;
	white-space: nowrap; /* Prevent title from wrapping to multiple lines */
	overflow: hidden;
	text-overflow: ellipsis; /* Show ellipsis (...) for long titles */
}

.article-description {
	padding-top: 10px;
	font-size: 12px;
	color: var(--secondary-color);
	font-weight: 480;
	line-height: 25px;
}

.article-link {
	padding-top: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

.article-see-project-link {
	color: #fff; /* or any color you originally had for 'See Project' */
	font-size: 24px;
	font-weight: bold;
	/* Add any other styles specific to 'See Project' link */
}

@media (max-width: 1024px) {
	.article-left-side {
		min-width: 100%; /* Make it full width on smaller screens */
		max-width: 100%;
		margin-right: 0; /* Remove spacing */
	}

	.article-right-side {
		flex-grow: 1; /* Allow it to grow and shrink on smaller screens */
		min-width: 0; /* Reset min-width */
	}
}

.article-container .wrapper {
	position: relative;
	height: 300px;
	width: 400px;
	display: flex;
	flex-direction: row;
	transition: all 400ms ease-in-out;
}

.article-container .image-wrapper {
	height: 300px;
	width: 400px;
	overflow: hidden;
	border-radius: 5px;
	cursor: pointer;
	transition: all 400ms ease-in-out;
}

.article-container img {
	height: 300px;
	width: 400px;
	object-fit: cover;
	object-position: top;
	transition: all 400ms ease-in-out;
}

.article-container .book-design-image {
	object-position: center;
}

.article-container .header-wrapper {
	display: flex;
	flex-direction: column;
	position: absolute;
	height: 300px;
	width: 300px;
	left: -2.5%;
	transition: all 400ms ease-in-out;
	overflow: hidden;
	pointer-events: none;
}

.article-container h1 {
	position: relative;
	bottom: 300px;
	height: 300px;
	color: #fff;
	font-size: 40px;
	font-family: Oswald;
	text-transform: uppercase;
	transition: all 400ms ease-in-out;
	transform: scale(0.4);
	z-index: 2;
}

.article-container:hover img {
	transform: scale(1.5);
}

.article-container:hover h1 {
	bottom: 300px;
	transform: scale(1.0);
	bottom: 0;
}

.article-container:hover .image-wrapper {
	width: 800px;
}

.article-container .article-link {
	text-decoration: none;
	color: #fff;
	font-size: 24px;
	font-weight: bold;
	position: absolute;
	bottom: 10px;
	left: 30px;
}

.article-container .header-wrapper h2 {
	opacity: 0;
	transition: opacity 400ms ease-in-out;
	z-index: 2;
}

.article-container:hover .header-wrapper h2 {
	opacity: 1;
}

/* Define styles for the overlay */
.article-container .image-wrapper::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: linear-gradient(-135deg, rgba(209, 175, 255, 0), rgba(68, 62, 255, 0.5));
	opacity: 0;
	transition: opacity 0.6s ease-in-out;
	border-radius: 5px;
	pointer-events: none;
	z-index: 2; /* Ensure it's behind the image */
}

/* Define styles for the GIF placeholder */
.article-container .image-wrapper .gif-placeholder {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 0; /* Set initial opacity to fully transparent */
	transition: opacity 0.6s ease-in-out;
	z-index: 1; /* Ensure it's behind the image */
}

/* Apply the overlay and GIF placeholder effect on hover */
.article-container .image-wrapper:hover::after,
.article-container .image-wrapper:hover .gif-placeholder {
	opacity: 1;
}