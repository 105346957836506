:root {
	/* ------- colors ------- */
	--primary-color: #ff6363;
	--secondary-color: #adc2ec;
	--tertiary-color: #5c94ac;
	--quaternary-color: #2d7c9a;
	--link-color: #e4799a;
	--primary-background: #2c2b4b;
	--secondary-background: #53476c;
	--tertiary-background: rgba(27, 24, 52, 1);
	--quaternary-background: #09051d;
	/* ---------------------- */

	/* Hello my name is gavin */
	background-color: #09051d;

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}

.projectDescription{
	color: var(--secondary-color);
}

a {
	color: var(--link-color); /* This will apply your custom link color to all <a> elements */
}

/* Change the scrollbar width and track color */
::-webkit-scrollbar {
	width: 9px; /* Adjust the width as needed */
	background-color: var(--tertiary-background);
}

/* Change the scrollbar thumb (handle) color */
::-webkit-scrollbar-thumb {
	background-color: var(--tertiary-background);
	border-radius: 9px; /* Round the scrollbar thumb */
}

/* Change the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: var(--secondary-background);
}

/* Change the scrollbar track when it's empty or not actively scrolling */
::-webkit-scrollbar-track {
	background-color: var(--quaternary-background);
}

/* Change the scrollbar corner (bottom-right corner) color */
::-webkit-scrollbar-corner {
	background-color: var(--tertiary-background);
}

.context {
	width: 100%;
	position: absolute;
	top:50vh;
}

.context h1{
	text-align: center;
	color: #fff;
	font-size: 50px;
}

.area {
	width: 100%;
	height: 100vh;
	position: fixed; /* Make it fixed in the viewport */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	pointer-events: none;
}

.circles{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.circles li{
	position: absolute;
	display: block;
	list-style: none;
	width: 10px;
	height: 20px;
	background: rgba(255, 255, 255, 0.2);
	animation: animate 25s linear infinite;
	bottom: -150px;
}

.circles li:nth-child(1){
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
}


.circles li:nth-child(2){
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 2s;
	animation-duration: 12s;
}

.circles li:nth-child(3){
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 4s;
}

.circles li:nth-child(4){
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 18s;
}

.circles li:nth-child(5){
	left: 65%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
}

.circles li:nth-child(6){
	left: 75%;
	width: 110px;
	height: 110px;
	animation-delay: 3s;
}

.circles li:nth-child(7){
	left: 35%;
	width: 150px;
	height: 150px;
	animation-delay: 7s;
}

.circles li:nth-child(8){
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 15s;
	animation-duration: 45s;
}

.circles li:nth-child(9){
	left: 20%;
	width: 15px;
	height: 15px;
	animation-delay: 2s;
	animation-duration: 35s;
}

.circles li:nth-child(10){
	left: 85%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 11s;
}



@keyframes animate {

	0%{
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100%{
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}

}