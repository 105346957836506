@import "../../../data/styles.css";

.footer {
	display: none;
	margin-top: 25px;
	align-items: center;
	width: 200%;
	background-color: var(--secondary-background);
}

.footer-links,
.footer-credits {
	flex-basis: 50%;
}

.footer-links {
	margin-left: -40px;
}

.footer-nav-link-list {
	display: flex;
	list-style: none;
	justify-content: space-between;
	align-items: center;
}

.footer-nav-link-item {
	font-weight: bold;
	font-size: 80%;
}

.footer-nav-link-list a {
	text-decoration: none;
	color: var(--secondary-color);
}

.footer-nav-link-list a:hover {
	color: var(--link-color);
}

.footer-credits-text {
	justify-content: flex-end;
	color: var(--tertiary-color);
	font-size: 14px;
	text-align: right;
}

@media (max-width: 250em) {
	.footer {
		height: 5px;
	}

	.footer-links {
		width: 200%;
		justify-content: center;
	}

	.footer-nav-link-list {
		width: 100%;
	}
}
